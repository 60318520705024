import { createImageHash } from './createImageHash';
import { getImage } from './getImage';

/**
 * Check if an image has already been persisted. This matching is
 * done based on a hash generated by {@linkcode createImageHash}.
 *
 * @param image - An image that may or may not have been persisted.
 *
 * @returns A `Promise` that resolves to `true` if the image has already
 * been persisted, or `false` if it hasn't been.
 */
export async function hasImage(image: Blob): Promise<boolean> {
	const hash = await createImageHash(image);
	const hasImage = Boolean(await getImage(hash));
	return hasImage;
}
